import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Shadow me to add your own bio content
 * 
 * (HS Taken from node_modules\gatsby-theme-blog\src\components\bio-content.js)
 */

export default () => (
  <Fragment>
    Ecrit par <Styled.a href="https://cocooning.netlify.com/">Harvey Stroud</Styled.a>.
    <br />
    <em>Le stylo est plus fort que l'épée.</em>
  </Fragment>
)
