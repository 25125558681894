import React from "react"
import { Link } from "gatsby"
import { css, Styled } from "theme-ui"
import Header from "gatsby-theme-blog/src/components/header"
//import { Trianglify } from "trianglify"
//TODO const Trianglify = require('trianglify'); // only needed in node.js

export default props => {
//! SO WORKS FOR PROD SSR
if (false) {/*
  // https://trianglify.io/p/w:1440!h:900!x:PRGn!v:0!c:0.18!s:74e3ck
  // https://github.com/qrohlf/trianglify
  // http://qrohlf.com/trianglify/
	const pattern = Trianglify({
		width: window.innerWidth,
    height: 320, //window.innerHeight,
    // cell_size: 100.18,
    // variance: 0,
    // seed: 74,
  });
*/}
  setTimeout(() => {
//! SO WORKS FOR PROD SSR
if (false) {/*
    const gatsbyFocusWrapper = document.querySelector('#gatsby-focus-wrapper');
    const header = gatsbyFocusWrapper.querySelector('header');
    const canvas = header.appendChild(pattern.canvas());
    canvas.style = `
      position: absolute;
      top: 0;
      z-index: -1;
      height: 320px;
      width: 100%;
    `;

    //const rootPath = `${__PATH_PREFIX__}/`
    //if (location.pathname === rootPath) {
    //}
    //else {
      let mainHeading = gatsbyFocusWrapper.querySelector('main > h1');

    if (!mainHeading) {
        // Try to find home page heading
        mainHeading = header.querySelector('h1');
    }

    mainHeading.style.textTransform = 'uppercase';
    mainHeading.style.color = '#eee';
    mainHeading.style.opacity = .6;
    mainHeading.style.fontSize = '4.5em';
    mainHeading.style.textShadow = 'rgb(0, 0, 0) 2px 2px 4px';
    
    const mainContent = gatsbyFocusWrapper.querySelector('main > h1 + p + *');
    if (mainContent) {
      mainContent.style.marginTop = '70px';
    }
*/}
  });

  return (
    <Header {...props}>
      <Styled.a
        as={Link}
        to="/notes"
        css={css({
          ml: 2,
          mr: `auto`,
          fontFamily: `heading`,
          fontWeight: `bold`,
          textDecoration: `none`,
          color: `inherit`,
          ":hover": {
            textDecoration: `underline`,
          },
        })}
      >
        Notes
      </Styled.a>
      <script>
        alert("here");
        initVideos();
      </script>
    </Header>
  );
}


/**
 * 
 */
let videoSequence;
let clips;
let currClip;
let currClipIndex;

let currQuizIndex;
let startTime;
let stopTime;
let paused;

/**
 * 
 * @param {*} _videoSequence 
 */
function initVideos() {
  const playVideoSequenceForm = document.querySelector('#play-video-sequence');

  if (playVideoSequenceForm) {
    //! const clips = document.querySelectorAll('[id^="imparfait-passe-compose-"');
    const videoSequence = {
      clips: [
        {
          video: document.querySelector('#imparfait-passe-compose-1'),
          quizzes: [
            document.querySelector('#quiz-1'),
            document.querySelector('#quiz-2'),
          ],
        },
        {
          video: document.querySelector('#imparfait-passe-compose-2'),
          quizzes: [
            document.querySelector('#quiz-3'),
            document.querySelector('#quiz-4'),
            document.querySelector('#quiz-5'),
          ],
        },
      ],
    };

    initVideoSequence(videoSequence);

    const playVideoSequenceSubmit = playVideoSequenceForm.querySelector('input[type="submit"]');
    playVideoSequenceSubmit.addEventListener('click', (event) => {
      event.preventDefault();
      playVideoSequenceForm.style.display = 'none';

      playClip(0);
    });
  }
}

/**
 * 
 * @param {*} _clips 
 * @param {*} _quizForms 
 */
function initVideoSequence(_videoSequence) {
  videoSequence = _videoSequence;
  clips = videoSequence.clips;
  initClip(0);
}

/**
 * 
 * @param {int} clipIndex 
 */
function initClip(clipIndex) {
  currClipIndex = clipIndex;
  currClip = clips[clipIndex];

  // Hide all but currently active clip
  for (let clip of clips) {
    clip.video.style.display = clip == currClip ? 'inherit' : 'none';

    // Hide all clip's quiz forms
    for (let quizForm of clip.quizzes) {
        quizForm.style.display = 'none';
    }
  }

}

/**
 * 
 * @param {int} clipIndex 
 */
function playClip(clipIndex) {
  // Detach current (to become previous) clip time update handler
  if (currClip) {
    currClip.video.removeEventListener('timeupdate', clipTimeUpdateHandler);
  }

  initClip(clipIndex);

  currQuizIndex = 0;
  startTime = 0.0;
  stopTime = 6.0; // TODO GET FROM PARAMS FILE ???
  paused = false;
  currClip.video.play();

  currClip.video.addEventListener('timeupdate', clipTimeUpdateHandler);
}

function clipTimeUpdateHandler(event) {
  if (this.currentTime >= stopTime && !paused) {
    currClip.video.pause();
    paused = true;

    // Activate quiz
    currClip.quizzes[currQuizIndex].style.display = 'inherit';
    const quizSubmit = currClip.quizzes[currQuizIndex].querySelector('input[type="submit"]');
    quizSubmit.addEventListener('click', submitQuizForm);
  }
}

/**
 * 
 * @param {*} event 
 */
function submitQuizForm(event) {
  event.preventDefault();

  let numCorrect = 0;
  const quizForm = currClip.quizzes[currQuizIndex];
  const questionRows = quizForm.querySelectorAll('tr.question');

  const numQuestions = questionRows.length;

  const answers = [];
  for (let index = 0; index < numQuestions; ++index) {
    const checkedCheckbox = quizForm.querySelector(`input[name="question-${index + 1}"]:checked`);
    if (!checkedCheckbox) {
      alert("Veuillez répondre à toutes les questions !");
      return;
    }
    answers.push(checkedCheckbox.value);
  }

  this.removeEventListener('click', submitQuizForm);

  if (currClipIndex == 0 && currQuizIndex == 0) {
      numCorrect =
      (answers[0] == 'false') +
      (answers[1] == 'true') +
      (answers[2] == 'true') +
      (answers[3] == 'false');
  }
  else if (currClipIndex == 0 && currQuizIndex == 1) {
    numCorrect =
      (answers[0] == 'true') +
      (answers[1] == 'false') +
      (answers[2] == 'false') +
      (answers[3] == 'false');
  }
  else if (currClipIndex == 1 && currQuizIndex == 0) {
    numCorrect =
      (answers[0] == 'false') +
      (answers[1] == 'false') +
      (answers[2] == 'true') +
      (answers[3] == 'true');
  }
  else if (currClipIndex == 1 && currQuizIndex == 1) {
    numCorrect =
      (answers[0] == 'true') +
      (answers[1] == 'true') +
      (answers[2] == 'true') +
      (answers[3] == 'true');
  }
  else if (currClipIndex == 1 && currQuizIndex == 2) {
    numCorrect =
      (answers[0] == 'false') +
      (answers[1] == 'false') +
      (answers[2] == 'true') +
      (answers[3] == 'true');
  }

  if (numCorrect == 4) {
    //alert("Très bien ! Nous allons passer à la partie suivante de la vidéo...")

    currClip.quizzes[currQuizIndex].style.display = 'none';

    if (currClipIndex == 0 && currQuizIndex == 0) {
      startTime = 60.0;
      stopTime = 64.0;
      currClip.video.currentTime = startTime;
      currClip.video.play();
      paused = false;
      ++currQuizIndex;
    }
    else if (currClipIndex == 0 && currQuizIndex == 1) {
      playClip(1);
    }
    else if (currClipIndex == 1 && currQuizIndex == 0) {
      startTime = 20.0;
      stopTime = 25.0;
      currClip.video.currentTime = startTime;
      currClip.video.play();
      paused = false;
      ++currQuizIndex;
    }
    else if (currClipIndex == 1 && currQuizIndex == 1) {
      startTime = 40.0;
      stopTime = 45.0;
      currClip.video.currentTime = startTime;
      currClip.video.play();
      paused = false;
      ++currQuizIndex;
    }
    else if (currClipIndex == 1 && currQuizIndex == 2) {
      alert("*** C'est la Fin ! ***");
    }
  }
  else {
    alert("Je vous invite de revoir la partie précédente de la vidéo afin de maîtriser les notions au mieux.")

    currClip.video.currentTime = startTime;
    currClip.quizzes[currQuizIndex].style.display = 'none';

      currClip.video.play();
      paused = false;
  }
}
